import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        primary: {
        main: '#1e88e5',
        }
    },
});

export default theme;